import React from 'react';
import { useTranslation } from 'react-i18next';
import MaterialCard from './MaterialCard';
import './Materials.css';

const Materials = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // Materials data for each language
  const materialsByLanguage = {
    fi: [
      {
        title: "Geego-juliste luokkaan",
        description: "Geegon A3-juliste inspiroi koko luokan 40 päivän ajan päivittäiseen Geego-taukojumppaan.",
        image: "/image/materials/geego-poster.webp",
        downloadUrl: "/image/materials/geego-poster.pdf"
      },
      {
        title: "Geego-video luokkaan",
        description: "Geegon video kertoo lapsille hänen tarinansa miten hän oppi nauttimaan liikunnasta ja miksi hän on täällä tänään. Käytä videota motivoidaksesi koko luokkaa gegoilemaan!",
        image: "/image/materials/geego-video-presentation FI.webp",
        downloadUrl: "/image/materials/geego-video-presentation FI.mp4"
      },
    ],
    en: [
      {
        title: "Geego Kids Exercise Cards",
        description: "Versatile exercise cards for developing children's motor skills.",
        image: "/image/materials/geego-poster.webp",
        downloadUrl: "/image/materials/geego-poster EN.pdf"
      },

      {
        title: "Geego video for the classroom",
        description: "Geego's video tells the children his story of how he learned to enjoy physical activity and why he is here today. Use the video to motivate the entire class to Geego!",
        image: "/image/materials/geego-video-presentation FI.webp",
        downloadUrl: "/image/materials/geego-video-presentation EN.mp4"
      },
     
    ],
    sv: [
      {
        title: "Geego-affisch till klassrummett",
        description: "Geegos A3-affisch inspirerar hela klassen under 40 dagar till daglig Geego-pausgympa.",
        image: "/image/materials/geego-poster.webp",
        downloadUrl: "/image/materials/geego-poster SE.pdf"
      },
      
      {
        title: "Geego-video till klassrummet",
        description: "Geegos video berättar för barnen hur han lärde sig att njuta av motion och varför han är här idag. Använd videon för att motivera hela klassen att delta i Geego!",
        image: "/image/materials/geego-video-presentation FI.webp",
        downloadUrl: "/image/materials/geego-video-presentation SE.mp4"
      },

    ]
  };

  const materials = materialsByLanguage[currentLanguage] || materialsByLanguage.fi;

  return (
    <div className="materials-section">
      <div className="materials-container">
        <h1 className="materials-title">{t('materials')}</h1>
        <div className="materials-grid">
          {materials.map((material, index) => (
            <MaterialCard
              key={index}
              title={material.title}
              description={material.description}
              image={material.image}
              downloadUrl={material.downloadUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Materials;
