import linkTypes from "../shared/MainLink/constants";

const feedbackUrls = {
    fi: "https://www.geegokids.com/fi/palaute/",
    en: "https://www.geegokids.com/en/feedback/",
    sv: "https://www.geegokids.com/sv/aterkoppling/"
};

export const getFeedbackUrl = (language) => {
    return feedbackUrls[language] || feedbackUrls.fi;
};

export const navigationLinksHeader = [
	{ name: "home-city", url: "/Challenges", type: linkTypes.DEFAULT },
	{ name: "home-sport", url: "/Skills", type: linkTypes.DEFAULT },
	{ name: "home-school", url: "/Toddlers", type: linkTypes.DEFAULT },
	{ name: "materials", url: "/materials", type: linkTypes.DEFAULT },
	{ name: "feedback", url: feedbackUrls.fi, type: linkTypes.EXTERNAL },
];
