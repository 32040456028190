import React from "react";
import { helpLinks } from "./constants";
import './HelpLinks.css';
import { createKey } from "../../shared/createKey";
import MainLink from "../../shared/MainLink/MainLink";
import { useTranslation } from 'react-i18next';
import { getFeedbackUrl } from "../../Header/constants";

const HelpLinks = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <div className="helpList">
      {helpLinks.map(({ url, name, type }) => {
        // If it's the feedback link, use the language-specific URL
        const finalUrl = name === 'feedback' ? getFeedbackUrl(i18n.language) : url;
        
        return (
          <MainLink
            url={finalUrl}
            key={createKey()}
            type={type}
            name={t(name)}
            openInNewTab={type === 'external'}
          >
            {t(name)} 
          </MainLink>
        );
      })}
    </div>
  );
};

export default HelpLinks;