import React from 'react';
import { useTranslation } from 'react-i18next';
import LazyImage from '../shared/LazyImage';
import './MaterialCard.css';

const MaterialCard = ({ title, description, image, downloadUrl }) => {
  const { t } = useTranslation();

  return (
    <div className="material-card">
      <div className="material-image-container">
        <LazyImage
          src={image}
          alt={title}
          className="material-image"
          fallbackSrc="/image/article-images/article1.webp"
        />
      </div>
      <div className="material-content">
        <h3 className="material-title">{title}</h3>
        <p className="material-description">{description}</p>
        <a 
          href={downloadUrl}
          className="download-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('downloadHere')}
        </a>
      </div>
    </div>
  );
};

export default MaterialCard;
