import React, { useState, useEffect, useRef } from 'react';
import './LazyImage.css';

const LazyImage = ({ 
  src, 
  alt, 
  onClick, 
  className = '',
  loadingClassName = 'lazy-image-loading',
  loadedClassName = 'lazy-image-loaded'
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: '50px'
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const imageClasses = [
    className,
    !isLoaded && loadingClassName,
    isLoaded && loadedClassName
  ].filter(Boolean).join(' ');

  return (
    <div ref={imgRef} className="lazy-image-container">
      {isInView ? (
        <img
          src={src}
          alt={alt}
          className={imageClasses}
          onLoad={handleLoad}
          onClick={onClick}
        />
      ) : (
        <div className="lazy-image-placeholder" />
      )}
      {!isLoaded && isInView && (
        <div className="lazy-image-loading-indicator">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default LazyImage;
