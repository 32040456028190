const ArticleFetchAPI = async (token, lang) => {
  // Helper function to create URL-friendly slugs
  const createSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/[äå]/g, 'a')
      .replace(/[ö]/g, 'o')
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  };

  // Mock articles for different languages with full content
  const mockArticles = {
    fi: [
      {
        id: 1,
        title: "Miksi taukoliikunta on tärkeää koulussa?",
        slug: "miksi-taukoliikunta-on-tarkeaa-koulussa",
        image: "/image/article-images/article1.webp",  // Updated image path
        content: `
          <h2>Taukoliikunnan merkitys oppimiselle</h2>
          <p>Säännöllinen taukoliikunta auttaa oppilaita keskittymään paremmin oppitunneilla. Lyhyetkin liikuntahetket parantavat aivojen verenkiertoa ja vireystilaa, mikä edistää oppimista ja jaksamista.</p>
          
          <h3>Taukoliikunnan hyödyt:</h3>
          <ul>
            <li>Parantaa keskittymiskykyä</li>
            <li>Lisää vireystilaa</li>
            <li>Edistää oppimista</li>
            <li>Vähentää istumisen haittoja</li>
            <li>Tuo energiaa koulupäivään</li>
          </ul>

          <h3>Miten toteuttaa taukoliikuntaa?</h3>
          <p>Taukoliikuntaa voi toteuttaa monella tavalla. Geego Kids -sovellus tarjoaa valmiita, helppoja ja hauskoja taukoliikuntaharjoituksia, joita voi tehdä luokkahuoneessa. Harjoitukset on suunniteltu niin, että ne sopivat kaiken ikäisille oppilaille ja niitä voi tehdä pienessäkin tilassa.</p>

          <h3>Milloin taukoliikuntaa kannattaa pitää?</h3>
          <p>Taukoliikuntaa suositellaan pidettäväksi:</p>
          <ul>
            <li>Oppitunnin puolivälissä</li>
            <li>Kun oppilaiden keskittyminen herpaantuu</li>
            <li>Pitkien istumajaksojen välissä</li>
            <li>Ennen uuden asian opettelua</li>
          </ul>
        `,
        description: "Säännöllinen taukoliikunta auttaa oppilaita keskittymään paremmin oppitunneilla...",
        date: new Date().toLocaleDateString()
      },
      {
        id: 2,
        title: "Geego Kids - Liikettä koulupäivään",
        slug: "geego-kids-liiketta-koulupaivaan",
        image: "/image/article-images/article2.webp",  // Updated image path
        content: `
          <h2>Geego Kids tuo liikettä koulupäivään</h2>
          <p>Geego Kids tarjoaa hauskoja ja innostavia taukoliikuntahetkiä koulupäivään. Sovelluksesta löydät monipuolisia liikuntatehtäviä, jotka on suunniteltu erityisesti luokkahuoneessa toteutettaviksi.</p>

          <h3>Sovelluksen ominaisuudet:</h3>
          <ul>
            <li>Valmiit taukoliikuntaohjelmat</li>
            <li>Helpot ja selkeät ohjeet</li>
            <li>Monipuoliset harjoitukset</li>
            <li>Soveltuu kaikenikäisille</li>
            <li>Toimii kaikilla laitteilla</li>
          </ul>

          <h3>Miten aloittaa Geego Kids -sovelluksen käyttö?</h3>
          <p>Sovelluksen käyttöönotto on helppoa:</p>
          <ol>
            <li>Kirjaudu sisään sovellukseen</li>
            <li>Valitse sopiva harjoitus</li>
            <li>Seuraa selkeitä ohjeita</li>
            <li>Nauti liikunnasta yhdessä oppilaiden kanssa</li>
          </ol>

          <p>Geego Kids tekee taukoliikunnasta hauskaa ja helppoa sekä opettajille että oppilaille!</p>
        `,
        description: "Geego Kids tarjoaa hauskoja ja innostavia taukoliikuntahetkiä koulupäivään...",
        date: new Date().toLocaleDateString()
      },
      {
        id: 3,
        title: "Aivotaukojen merkitys oppimiselle",
        slug: "aivotaukojen-merkitys-oppimiselle",
        image: "/image/article-images/article3.webp",  // Updated image path
        content: `
          <h2>Aivotauot tehostavat oppimista</h2>
          <p>Tutkimukset osoittavat, että säännölliset aivotauot ja liikuntahetket parantavat oppimistuloksia. Jo 5-10 minuutin aktiivinen tauko tunnin aikana auttaa ylläpitämään oppilaiden keskittymiskykyä.</p>

          <h3>Aivotaukojen vaikutukset:</h3>
          <ul>
            <li>Parantavat muistia ja oppimiskykyä</li>
            <li>Lisäävät luovuutta</li>
            <li>Vähentävät stressiä</li>
            <li>Parantavat keskittymiskykyä</li>
            <li>Lisäävät motivaatiota</li>
          </ul>

          <h3>Tieteellinen tausta</h3>
          <p>Aivotutkimukset ovat osoittaneet, että säännölliset tauot ja liike aktivoivat aivojen eri alueita ja edistävät uuden tiedon omaksumista. Liikunta lisää aivojen verenkiertoa ja välittäjäaineiden tuotantoa, mikä tehostaa oppimista.</p>

          <h3>Käytännön vinkkejä</h3>
          <p>Aivotaukojen pitämiseen:</p>
          <ul>
            <li>Pidä säännöllisiä taukoja</li>
            <li>Yhdistä liike ja oppiminen</li>
            <li>Kannusta oppilaita liikkumaan</li>
            <li>Käytä Geego Kids -sovelluksen harjoituksia</li>
          </ul>
        `,
        description: "Tutkimukset osoittavat, että säännölliset aivotauot ja liikuntahetket parantavat oppimistuloksia...",
        date: new Date().toLocaleDateString()
      }
    ],
    en: [
      {
        id: 1,
        title: "Why are Brain Breaks Important at School?",
        slug: "why-are-brain-breaks-important-at-school",
        image: "/image/article-images/article1.webp",  // Updated image path
        content: `
          <h2>The Importance of Brain Breaks for Learning</h2>
          <p>Regular brain breaks help students focus better during lessons. Even short exercise moments improve brain circulation and alertness, which enhances learning and endurance.</p>
          
          <h3>Benefits of Brain Breaks:</h3>
          <ul>
            <li>Improves concentration</li>
            <li>Increases alertness</li>
            <li>Enhances learning</li>
            <li>Reduces negative effects of sitting</li>
            <li>Brings energy to the school day</li>
          </ul>

          <h3>How to Implement Brain Breaks?</h3>
          <p>Brain breaks can be implemented in many ways. The Geego Kids app provides ready-made, easy, and fun exercise breaks that can be done in the classroom. The exercises are designed to suit students of all ages and can be done in small spaces.</p>

          <h3>When to Have Brain Breaks?</h3>
          <p>Brain breaks are recommended:</p>
          <ul>
            <li>In the middle of lessons</li>
            <li>When students' concentration wanes</li>
            <li>Between long sitting periods</li>
            <li>Before learning new material</li>
          </ul>
        `,
        description: "Regular brain breaks help students focus better during lessons...",
        date: new Date().toLocaleDateString()
      },
      {
        id: 2,
        title: "Geego Kids - Movement for School Days",
        slug: "geego-kids-movement-for-school-days",
        image: "/image/article-images/article2.webp",  // Updated image path
        content: `
          <h2>Geego Kids Brings Movement to School Days</h2>
          <p>Geego Kids offers fun and inspiring exercise breaks for the school day. In the app, you'll find versatile physical activities specifically designed for classroom implementation.</p>

          <h3>App Features:</h3>
          <ul>
            <li>Ready-made exercise programs</li>
            <li>Easy and clear instructions</li>
            <li>Versatile exercises</li>
            <li>Suitable for all ages</li>
            <li>Works on all devices</li>
          </ul>

          <h3>How to Start Using Geego Kids?</h3>
          <p>Getting started is easy:</p>
          <ol>
            <li>Log in to the app</li>
            <li>Choose a suitable exercise</li>
            <li>Follow clear instructions</li>
            <li>Enjoy exercising together with students</li>
          </ol>

          <p>Geego Kids makes exercise breaks fun and easy for both teachers and students!</p>
        `,
        description: "Geego Kids offers fun and inspiring exercise breaks for the school day...",
        date: new Date().toLocaleDateString()
      },
      {
        id: 3,
        title: "The Impact of Brain Breaks on Learning",
        slug: "impact-of-brain-breaks-on-learning",
        image: "/image/article-images/article3.webp",  // Updated image path
        content: `
          <h2>Brain Breaks Enhance Learning</h2>
          <p>Research shows that regular brain breaks and exercise moments improve learning outcomes. Just 5-10 minutes of active break during a lesson helps maintain students' concentration.</p>

          <h3>Effects of Brain Breaks:</h3>
          <ul>
            <li>Improve memory and learning ability</li>
            <li>Increase creativity</li>
            <li>Reduce stress</li>
            <li>Enhance concentration</li>
            <li>Increase motivation</li>
          </ul>

          <h3>Scientific Background</h3>
          <p>Brain research has shown that regular breaks and movement activate different areas of the brain and promote the absorption of new information. Exercise increases brain circulation and neurotransmitter production, which enhances learning.</p>

          <h3>Practical Tips</h3>
          <p>For implementing brain breaks:</p>
          <ul>
            <li>Take regular breaks</li>
            <li>Combine movement and learning</li>
            <li>Encourage students to move</li>
            <li>Use Geego Kids app exercises</li>
          </ul>
        `,
        description: "Research shows that regular brain breaks and exercise moments improve learning outcomes...",
        date: new Date().toLocaleDateString()
      }
    ],
    sv: [
      {
        id: 1,
        title: "Varför är pausgymnastik viktigt i skolan?",
        slug: "varfor-ar-pausgymnastik-viktigt-i-skolan",
        image: "/image/article-images/article1.webp",  // Updated image path
        content: `
          <h2>Betydelsen av pausgymnastik för inlärning</h2>
          <p>Regelbunden pausgymnastik hjälper eleverna att koncentrera sig bättre under lektionerna. Även korta träningsmoment förbättrar hjärnans blodcirkulation och vakenhet, vilket främjar inlärning och uthållighet.</p>
          
          <h3>Fördelar med pausgymnastik:</h3>
          <ul>
            <li>Förbättrar koncentrationsförmågan</li>
            <li>Ökar vakenheten</li>
            <li>Främjar inlärning</li>
            <li>Minskar negativa effekter av sittande</li>
            <li>Ger energi till skoldagen</li>
          </ul>

          <h3>Hur implementera pausgymnastik?</h3>
          <p>Pausgymnastik kan implementeras på många sätt. Geego Kids-appen erbjuder färdiga, enkla och roliga träningspauser som kan göras i klassrummet. Övningarna är utformade för att passa elever i alla åldrar och kan göras i små utrymmen.</p>

          <h3>När ska man ha pausgymnastik?</h3>
          <p>Pausgymnastik rekommenderas:</p>
          <ul>
            <li>I mitten av lektioner</li>
            <li>När elevernas koncentration avtar</li>
            <li>Mellan långa sittperioder</li>
            <li>Innan man lär sig nytt material</li>
          </ul>
        `,
        description: "Regelbunden pausgymnastik hjälper eleverna att koncentrera sig bättre under lektionerna...",
        date: new Date().toLocaleDateString()
      },
      {
        id: 2,
        title: "Geego Kids - Rörelse för skoldagen",
        slug: "geego-kids-rorelse-for-skoldagen",
        image: "/image/article-images/article2.webp",  // Updated image path
        content: `
          <h2>Geego Kids ger rörelse till skoldagen</h2>
          <p>Geego Kids erbjuder roliga och inspirerande träningspauser för skoldagen. I appen hittar du mångsidiga fysiska aktiviteter som är speciellt utformade för klassrumsimplementering.</p>

          <h3>Appens funktioner:</h3>
          <ul>
            <li>Färdiga träningsprogram</li>
            <li>Enkla och tydliga instruktioner</li>
            <li>Mångsidiga övningar</li>
            <li>Passar alla åldrar</li>
            <li>Fungerar på alla enheter</li>
          </ul>

          <h3>Hur börjar man använda Geego Kids?</h3>
          <p>Att komma igång är enkelt:</p>
          <ol>
            <li>Logga in i appen</li>
            <li>Välj en lämplig övning</li>
            <li>Följ tydliga instruktioner</li>
            <li>Njut av träning tillsammans med eleverna</li>
          </ol>

          <p>Geego Kids gör träningspauser roliga och enkla för både lärare och elever!</p>
        `,
        description: "Geego Kids erbjuder roliga och inspirerande träningspauser för skoldagen...",
        date: new Date().toLocaleDateString()
      },
      {
        id: 3,
        title: "Hjärnpausers betydelse för inlärning",
        slug: "hjarnpausers-betydelse-for-inlarning",
        image: "/image/article-images/article3.webp",  // Updated image path
        content: `
          <h2>Hjärnpauser förbättrar inlärningen</h2>
          <p>Forskning visar att regelbundna hjärnpauser och träningsmoment förbättrar inlärningsresultaten. Bara 5-10 minuters aktiv paus under en lektion hjälper till att upprätthålla elevernas koncentration.</p>

          <h3>Effekter av hjärnpauser:</h3>
          <ul>
            <li>Förbättrar minne och inlärningsförmåga</li>
            <li>Ökar kreativiteten</li>
            <li>Minskar stress</li>
            <li>Förbättrar koncentrationen</li>
            <li>Ökar motivationen</li>
          </ul>

          <h3>Vetenskaplig bakgrund</h3>
          <p>Hjärnforskning har visat att regelbundna pauser och rörelse aktiverar olika områden i hjärnan och främjar absorption av ny information. Träning ökar hjärnans cirkulation och produktion av signalsubstanser, vilket förbättrar inlärningen.</p>

          <h3>Praktiska tips</h3>
          <p>För att implementera hjärnpauser:</p>
          <ul>
            <li>Ta regelbundna pauser</li>
            <li>Kombinera rörelse och inlärning</li>
            <li>Uppmuntra elever att röra på sig</li>
            <li>Använd Geego Kids-appens övningar</li>
          </ul>
        `,
        description: "Forskning visar att regelbundna hjärnpauser och träningsmoment förbättrar inlärningsresultaten...",
        date: new Date().toLocaleDateString()
      }
    ]
  };

  try {
    console.log('Fetching articles for language:', lang);
    
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 500));
    
    // Return mock articles for the selected language, fallback to Finnish
    const articles = mockArticles[lang] || mockArticles.fi;
    console.log('Returning articles:', articles);
    return articles;

  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};

export default ArticleFetchAPI;
