import MainLink from '../shared/MainLink/MainLink';
import './Menu.css';
import { useTranslation } from 'react-i18next';
import { navigationLinksHeader, getFeedbackUrl } from "./constants";
import { createKey } from './createKey';

const Menu = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <ul className="menu">
      {navigationLinksHeader.map(({ url, name, type }) => {
        // If it's the feedback link, use the language-specific URL
        const finalUrl = name === 'feedback' ? getFeedbackUrl(currentLanguage) : url;
        
        return (
          <MainLink 
            url={finalUrl} 
            key={createKey()} 
            type={type} 
            name={name}
            openInNewTab={type === 'external'}
          >
            {t(name)}
          </MainLink>
        );
      })}
    </ul>
  );
};

export default Menu;
