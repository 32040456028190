import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LazyImage from '../../shared/LazyImage';
import './ArticleCard.css';

const ArticleCard = ({ title, image, slug }) => {
  const { t } = useTranslation();

  return (
    <div className="article-card">
      <div className="article-image-container">
        <LazyImage
          src={image}
          alt={title}
          className="article-image"
          fallbackSrc="/image/article-images/article1.webp"  // Updated fallback image
        />
      </div>
      <div className="article-content">
        <h3 className="article-title">{title}</h3>
        <Link 
          to={`/article/${slug}`} 
          className="read-more-link"
        >
          {t('readMore')}
        </Link>
      </div>
    </div>
  );
};

export default ArticleCard;
