import linkTypes from "../../shared/MainLink/constants";
import { getFeedbackUrl } from "../../Header/constants";

export const navigationLinks = [
	{ name: "home-city", url: "/Challenges", type: linkTypes.DEFAULT },
	{ name: "home-sport", url: "/Skills", type: linkTypes.DEFAULT },
	{ name: "home-school", url: "/Toddlers", type: linkTypes.DEFAULT },
];

export const helpLinks = [
  { name: "feedback", url: getFeedbackUrl("fi"), type: linkTypes.EXTERNAL },
  { name: "material", url: "https://www.geegokids.com/fi/materiaalit/", type: linkTypes.DEFAULT },
];
