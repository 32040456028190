import React from "react";
import "./Button.css";
import "../index.css";

const Button = ({
  onClick,
  bgColor,
  bgColor2,
  imgUrl,
  name,
  description,
  className = "",
  size = "medium",
  variant = "primary",
  disabled = false,
}) => {
  return (
    <div className={`Button ${className}`}>
      <button 
        onClick={onClick}
        className={`modern-button ${size} ${variant}`}
        style={{ backgroundColor: bgColor }}
        disabled={disabled}
      >
        {imgUrl && (
          <img
            src={imgUrl}
            alt={`${name} icon`}
            className="button-icon"
          />
        )}
        {name && (
          <p 
            className="CategoryButton" 
            style={{ backgroundColor: bgColor2 }}
          >
            {name}
          </p>
        )}
      </button>
      {description && (
        <p className="description">{description}</p>
      )}
    </div>
  );
};

export default Button;
