import React from "react";
import './ToddlersSubCategory.css';
import MainLink from '../shared/MainLink/MainLink';
import LazyImage from '../shared/LazyImage';

const ToddlersSubCategory = (props) => {
  return (
    <MainLink href={`/Toddlers/${props.title}`}>
      <div className="container-card">
        <div className="imgContainer">
          <LazyImage 
            src={props.imgUrl} 
            alt={props.title}
            className="toddler-category-image"
          />
        </div>
        <div className="content">
          <button className="title-card">
            <span>{props.title}</span>
          </button>
        </div>
      </div>
    </MainLink>
  );
};

export default ToddlersSubCategory;