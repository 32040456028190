import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import ChallengesTask from "./ChallengesTask";
import "./ChallengesTasks.css";
import ModalChallengesVideo from './ModalChallengesVideo';

const ChallengesTasks = (props) => {
  const { title } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredChallenge, setFilteredChallenge] = useState(null);

  useEffect(() => {
    if (props.challengesState) {
      const challenge = props.challengesState
        .flatMap(subcategory => subcategory.challenges)
        .find(challenge => challenge.title === title);
      
      setFilteredChallenge(challenge);
      setIsLoading(false);
    }
  }, [props.challengesState, title]);

  if (isLoading) {
    return (
      <div className="section-tasks">
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="section-tasks">
      {props.challengesState && props.challengesState.map((challengesSubcategory) => {
        return challengesSubcategory.challenges
          .filter((challenge) => challenge.title === title)
          .map((challenge, index) => {
            return (
              <div key={index}>
                <div className="headerContainer-tasks">
                  <div className="wrapper-tasks">
                    <h1 className="title-challenges-tasks">{challenge.title}</h1>
                    <p className="text-tasks">{challenge.description}</p>
                  </div>
                </div>
                <ul className="list-tasks">
                  {challenge.task_groups.map((taskGroup) => 
                    taskGroup.tasks.map((task) => (
                      <ChallengesTask
                        key={task.id}
                        imgUrl={task.thumbnail}
                        title={task.title}
                        video={task.video}
                        description={task.description}
                        allTasks={taskGroup.tasks}
                        allTaskGroups={challenge.task_groups} 
                      />
                    ))
                  )}
                </ul>
              </div>
            );
          });
      })}
      <ModalChallengesVideo />
    </div>
  );
};

export default ChallengesTasks;