import React from "react";
import './DinoHero.css';
import './HeroSection.css';
import { useTranslation } from 'react-i18next';

const DinoHero = () => {
  const { t } = useTranslation();

  return (
    <div className="mentor">
      <div className="mentor-container">
        <div className="mentor-wrapper">
          <div className="mentor-text">
        
            <div className="textContainer">
              <p>{t("slogan")}</p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default DinoHero;
