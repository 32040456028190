import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./component/Home";
import AtNurseries from "./component/AtNurseries";
import AtSchool from "./component/AtSchool";
import Header from './component/Header/Header';
import ChallengesTasks from "./component/Challenges/ChallengesTasks";
import SkillsTasks from "./component/Skills/SkillsTasks";
import Toddlers from "./component/Toddlers/Toddlers";
import ToddlersTasks from "./component/Toddlers/ToddlersTasks";
import Login from "./component/Login";
import useToken from "./useToken";
import ChallengesFetchAPI from "./ChallengesFetchAPI";
import ToddlersFetchAPI from "./ToddlersFetchAPI";
import SkillsFetchAPI from "./SkillsFetchAPI";
import "./App.css";
import Challenges from './component/Challenges/Challenges';
import Skills from './component/Skills/Skills';
import Footer from './component/Footer/Footer';
import i18n from './i18n';
import ArticlePage from './component/Home/ArticleSection/ArticlePage';
import Materials from './component/Materials/Materials';

const App = () => {
  const { token, setToken } = useToken();
  const [challengesState, setChallengesState] = useState();
  const [skillsState, setSkillsState] = useState();
  const [toddlersState, setToddlersState] = useState();
  const [language, setLanguage] = useState(() => localStorage.getItem("lang") || "fi");

  useEffect(() => {
    const fetchData = async () => {
      if (!token) return;
      
      // Fetch all data in parallel
      const [challenges, skills, toddlers] = await Promise.all([
        ChallengesFetchAPI(token, language),
        SkillsFetchAPI(token, language),
        ToddlersFetchAPI(token, language)
      ]);
      
      setChallengesState(challenges);
      setSkillsState(skills);
      setToddlersState(toddlers);
    };

    fetchData();
  }, [token, language]);

  const handleLanguageChange = (newLang) => {
    setLanguage(newLang);
    localStorage.setItem("lang", newLang);
    i18n.changeLanguage(newLang);
  };

  if (!token) {
    return (
      <Login 
        setToken={setToken} 
        currentLocale={language} 
        onLanguageChange={handleLanguageChange} 
      />
    );
  }

  return (
    <Router>
      <div>
        <Header 
          token={token} 
          currentLocale={language} 
          onLanguageChange={handleLanguageChange} 
        />
        <Routes>
          {/* Protected Routes */}
          <Route path="/" element={
            token ? <Home token={token} language={language} /> : <Login setToken={setToken} currentLocale={language} onLanguageChange={handleLanguageChange} />
          } />
          <Route path="/AtNurseries" element={
            token ? <AtNurseries /> : <Login setToken={setToken} currentLocale={language} onLanguageChange={handleLanguageChange} />
          } />
          <Route path="/AtSchool" element={
            token ? <AtSchool /> : <Login setToken={setToken} currentLocale={language} onLanguageChange={handleLanguageChange} />
          } />
          <Route path="/Challenges" element={
            token ? <Challenges challengesState={challengesState} /> : <Login setToken={setToken} currentLocale={language} onLanguageChange={handleLanguageChange} />
          } />
          <Route path="/Challenges/:title" element={
            token ? <ChallengesTasks challengesState={challengesState} /> : <Login setToken={setToken} currentLocale={language} onLanguageChange={handleLanguageChange} />
          } />
          <Route path="/Skills" element={
            token ? <Skills skillsState={skillsState} /> : <Login setToken={setToken} currentLocale={language} onLanguageChange={handleLanguageChange} />
          } />
          <Route path="/Skills/:title" element={
            token ? <SkillsTasks skillsState={skillsState} /> : <Login setToken={setToken} currentLocale={language} onLanguageChange={handleLanguageChange} />
          } />
          <Route path="/Toddlers" element={
            token ? <Toddlers toddlersState={toddlersState} /> : <Login setToken={setToken} currentLocale={language} onLanguageChange={handleLanguageChange} />
          } />
          <Route path="/Toddlers/:title" element={
            token ? <ToddlersTasks toddlersState={toddlersState} /> : <Login setToken={setToken} currentLocale={language} onLanguageChange={handleLanguageChange} />
          } />
          <Route path="/article/:articleSlug" element={
            token ? <ArticlePage token={token} language={language} /> : <Login setToken={setToken} currentLocale={language} onLanguageChange={handleLanguageChange} />
          } />
          <Route path="/materials" element={
            token ? <Materials /> : <Login setToken={setToken} currentLocale={language} onLanguageChange={handleLanguageChange} />
          } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
