import React, { useState, useEffect } from "react";
import './Home/Categories.css';
import HeroSection from './Home/HeroSection/HeroSection';
import Categories from './Home/Categories';
import ArticleSection from './Home/ArticleSection/ArticleSection';
import ArticleFetchAPI from '../ArticleFetchAPI';

const Home = ({ token, language }) => {
  const [articles, setArticles] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      if (!token) return;
      const articlesData = await ArticleFetchAPI(token, language);
      setArticles(articlesData);
    };

    fetchArticles();
  }, [token, language]);

  return (
    <div>
      <HeroSection />
      <Categories />
      <ArticleSection articles={articles} />
    </div>
  );
};

export default Home;
