import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useToken from '../../../useToken';
import ArticleFetchAPI from '../../../ArticleFetchAPI';
import './ArticlePage.css';

const ArticlePage = () => {
  const { articleSlug } = useParams();  // Changed from articleId to articleSlug
  const { token } = useToken();
  const { t, i18n } = useTranslation();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const currentLang = i18n.language;

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        // Get all articles for the current language
        const articles = await ArticleFetchAPI(token, currentLang);
        
        // Find the specific article by slug
        const foundArticle = articles.find(a => a.slug === articleSlug);
        
        if (!foundArticle) {
          throw new Error('Article not found');
        }

        setArticle(foundArticle);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching article:', error);
        setIsLoading(false);
      }
    };

    if (token && articleSlug) {
      fetchArticle();
    }
  }, [articleSlug, token, currentLang]);

  if (isLoading) {
    return (
      <div className="article-page-loading">
        <div className="spinner"></div>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="article-page">
        <div className="article-page-container">
          <p>{t('articleNotFound')}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="article-page">
      <div className="article-page-container">
        <div className="article-page-content">
          <h1>{article.title}</h1>
          {article.image && (
            <div className="article-page-image-container">
              <img src={article.image} alt={article.title} className="article-page-image" />
            </div>
          )}
          <div className="article-page-text" dangerouslySetInnerHTML={{ __html: article.content }} />
          <div className="article-page-date">
            {t('publishedOn')}: {article.date}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
