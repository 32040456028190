import React from "react";
import './LanguageBtnLogin.css';
import i18n from '../i18n';

const locales = ["en", "fi", "sv"]; 

export default function LanguageBtnLogin({ currentLocale, onLanguageChange }) {
  const handleCheckLocale = (item) => {
    onLanguageChange(item); // server lang change func
    i18n.changeLanguage(item);  // local lang change func
  };

  return (
    <div className="language-selector">
      {locales.map((locale) => (
        <button
          key={locale}
          onClick={() => handleCheckLocale(locale)}
          className={`lang-btn ${locale === currentLocale ? 'active' : ''}`}
          type="button"
        >
          <img
            src={`/image/flags/${locale}.png`}
            alt={`${locale} flag`}
            className="flag_icon"
            width={20}
            height={20}
          />
          {locale.toUpperCase()}
        </button>
      ))}
    </div>
  );
}
