import useStateModal from './stateModalSubCategoryGroup';
import './ChallengesSubCategory.css';
import LazyImage from '../shared/LazyImage';

const ChallengesSubCategory = (props) => {
  const open = useStateModal((state) => state.open);
  const setSelectedData = useStateModal((state) => state.setSelectedData);

  const handleClick = () => {
    setSelectedData(props);
    open();
  };

  return (
    <div className="container-card">
      <button className="title-card" onClick={handleClick}>
        <div className="imgContainer">
          <LazyImage 
            src={props.imgUrl} 
            alt={props.title}
            className="challenge-category-image"
          />
        </div>
        <div className="content">
          <span>{props.title}</span>
        </div>
      </button>
    </div>
  );
};

export default ChallengesSubCategory;