
export const items = [
  {
    image: '/image/2023/ikoni_geegocity.png',
    title: 'home-city',
    description: 'home-city-description',
    cardBackground: 'rgb(107, 55, 124)', 
    url: "/Challenges",
    
  },
  {
    image: '/image/2023/ikoni_sporttiparkki.png',
    title: "home-sport",
    description: "home-sport-description",
    cardBackground: 'rgb(0, 116, 172)',
    url: "/Skills",
  },
  {
    image: '/image/2023/ikoni_koulu.png',
    title: "home-school",
    description: "home-sport-description",
    cardBackground: 'rgb(235, 106, 57)', 
    url: "/Toddlers",
  },
];