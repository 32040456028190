import React from 'react';
import { useTranslation } from 'react-i18next';
import ArticleCard from './ArticleCard';
import './ArticleSection.css';

const ArticleSection = ({ articles }) => {
  const { t } = useTranslation();

  const LoadingPlaceholder = () => (
    <div className="loading-placeholder">
      <div className="article-placeholder"></div>
      <div className="article-placeholder"></div>
      <div className="article-placeholder"></div>
    </div>
  );

  return (
    <section className="article-section">
      <div className="article-section-container">
        <div className="article-section-title">
          <h2>{t('articleSection')}</h2>
        </div>
        <div className="article-section-content">
          {!articles ? (
            <LoadingPlaceholder />
          ) : articles.length === 0 ? (
            <p>No articles available</p>
          ) : (
            articles.map((article) => (
              <ArticleCard
                key={article.id}
                title={article.title}
                image={article.image || '/image/2023/ikoni_geegocity.png'}
                slug={article.slug}
              />
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default ArticleSection;
